import React from 'react'
import GalleryBackground from '../../components/galleryBackground'
import GalleryGridFancy from '../../components/galleryGridFancy'
import miscRo from "../../images/rollovers/graphic-design/misc-ro.png"
import "../layout.css"

import misc1 from '../../images/graphic-design/misc/misc1.jpg'
import misc2 from '../../images/graphic-design/misc/misc2.jpg'
import misc3 from '../../images/graphic-design/misc/misc3.jpg'
import misc4 from '../../images/graphic-design/misc/misc4.jpg'
import misc5 from '../../images/graphic-design/misc/misc5.jpg'
import misc6 from '../../images/graphic-design/misc/misc6.jpg'

export default function Misc() {
    let images = [misc1, misc2, misc3, misc4, misc5, misc6]
    return (
        <GalleryBackground title="Misc" imgSrc={miscRo}>
            <GalleryGridFancy imgArray={images} />
        </GalleryBackground>
    )
}